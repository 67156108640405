import React from "react";

import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import GaziModel from "./GaziModel";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FadeInSection from "./FadeInSection";
import "../styles/Video.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";

class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      visible: true
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const one = (
      <p style={{marginBottom:4+'rem'}}>
       I'm a research software intern based in Geneva, Switzerland. I enjoy spending my time writing code and exploring scientific software, Previously I worked on some cool projects like self-driving cars and autonomous drones.
      </p>
    );
    return (
      
      <div id="intro">
  
       <div id="intro-gallery">
       <div className="intro-video1">
            <video
                autoPlay
                loop
                muted
                className="d-block w-100"
                src="/assets/aachen_talk.mp4"
                type = 'video/mp4'
              />
            </div>
        <div className="intro-image">
            <img src={"/assets/workshop.jpg"} />
        </div>
          </div>
  
        
        <FadeInSection>
        
          <div className="about-content">
            
            <div className="intro-desc">


            <div>

<Typist avgTypingDelay={120}>
<span className="intro-title">
{"Hi, I'm"}
<span className="intro-name">{" Aaron"}</span>
</span>
</Typist>
<div className="intro-subtitle">Scientific Software Developer</div>
</div>

            {[one]}
            <div className="contact-div"><a
            href="mailto:aaronjomyjoseph@gmail.com"
            className="intro-contact"
          >
            <EmailRoundedIcon></EmailRoundedIcon>
            {"Send me a mail"}
          </a>
        
          {/* <a
            href="https://drive.google.com/file/d/1_hZsPauxEYDcMKKIPdt6Rf3GXKvPNBYv/view?usp=sharing"
            className="intro-contact"
          >
            <EmailRoundedIcon></EmailRoundedIcon>
            {"  " + "Download my CV"}
          </a> */}
          </div>
            </div>
      
          
          {/* <br></br> */}
          
          <div className="intro-gallery2">
            <img src={"/assets/atlas.jpg"}  />
          </div>
            <div className="intro-image2">
            <video
                autoPlay
                loop
                muted
                className="d-block w-100"
                src="/assets/ev3.mp4"
                type = 'video/mp4'
              />
            </div>
            <div className="intro-gallery2">
            <img src={"/assets/me_drone.jpg"}  />
          </div>
          </div>

          
        </FadeInSection>
      </div>
      
    );
  }
}


// return (
//   <div id="about">
//     <FadeInSection>
//       <div className="section-header ">
//         <span className="section-title">/ about me</span>
//       </div>
//       <div className="about-content">
//         <div className="about-description">
//           {"Here are some technologies I have been working with:"}
//         </div>
//         <div className="about-image">
//           <img src={"/assets/me_drone.jpg"} />
//         </div>
//       </div>
//     </FadeInSection>
//   </div>
// );

export default Intro;
