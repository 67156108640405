import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "CERN": {
      jobTitle: "Research Software Intern -",
      company: "CERN",
      duration: "JAN 2024 - AUG 2024",
      link: "https://summerofcode.withgoogle.com/archive/2022/organizations/cern-hsf",
      desc: [
        "Working with the ROOT team on developing high-performance scientific computing software used by the Large Hadron Collider experiments at CERN.",
        "Focusing on computing primitives in C++ that deal with scientific analysis, statistical fitting, and minimization made available in Python through automatic LLVM-based bindings.",
        "Responsible for the incremental adoption of novel interpreter infrastructure, enhancing compiler abstractions for faster performance, features, and GPU support.",
        
      ]
    },
    "Princeton University": {
      jobTitle: "Research Software Intern -",
      company: "Princeton University",
      duration: "JAN 2024 - AUG 2024",
      link: "https://summerofcode.withgoogle.com/archive/2022/organizations/cern-hsf",
      desc: [
        "Responsible for the research and development of CppInterOp, a novel C++ interoperability mechanism based on interactive C++ technologies available in Clang and LLVM.",
        "Maintained the CI and open source development workflow, reviewed external contributor work and mentored three Google Summer of Code students.",
        "Accepted paper on Python C++ Interoperability at the Conference on Computing in High Energy and Nuclear Physics(CHEP)",
        "Presented a tutorial for an invited talk on “Fast and Efficient Computing” to PhD students based at the DESY research centre."
      ]
    },
    "Google Summer of Code": {
      jobTitle: "Student Software Developer -",
      company: "Compiler Research Lab, CERN-HSF",
      duration: "MAY 2023 - SEPT 2023 ",
      link: "https://summerofcode.withgoogle.com/archive/2022/organizations/cern-hsf",
      desc: [
        "Selected as a GSoC contributer under the CERN-HSF (High-Energy Physics Software Foundation), the umbrella organization for high-energy physics projects in GSoC.",
        "My accepted proposal was on Python-C++ language interoperability. Added support for Numba Just-In-Time(JIT) compilation for C++ bound Python functions, using the Eigen templated library for matrix operations.",
        "Resulted in 5 - 100x speedups over BLAS optimized NumPy operations, made available a broader feature set of C++ in Numba and accelerated codebases that use cppyy by 20x",
        
      ]
    },
     "IISc Bangalore": {
      jobTitle: "AI and Robotics Research Intern -",
      company: "Indian Institute of Science Bangalore",
      link: "https://iisc.ac.in/research/",
      duration: "MAY 2022 - JUN 2023",
      desc: [
        "Performed research under Professor Suresh Sundaram at the Artificial Intelligence and Robotics Lab(AIRL)",
        "Research and Development on Autonomous Exploration for Parent-Child swarm systems using broadcast communication for emergency response applications.",
        "Developed and successfully demonstrated a GPS-denied lane-aware navigation system for the lab’s Autonomous UGV platform Copernicus."
      ]
    },
    "Peer Robotics": {
      jobTitle: "Robotic Software Engineering Intern -",
      company: "Peer Robotics",
      duration: "MAR 2022 - AUG 2022",
      link: "https://www.peerrobotics.ai/",
      desc: [
       "Developed a negative ground and obstacle detection and segmentation module using LiDAR and stereo camera fused point cloud data on industrial robots.",
       "Developed a basic homotopy class-based navigation testbench. Implemented L-Augmented graphs and Cauchy Integrals for path selection and faster controller performance."
      ]
    },
    "Project MANAS": {
      jobTitle: "Head of AI and Robotics -",
      company: "Project MANAS",
      duration: "MAY 2021 - AUG 2023",
      link: "https://www.projectmanas.in/#projects",
      desc: [
        "Led a software team of 11 students in the development of an autonomous car.",
        "Led the design and development of the autonomous navigation and control systems software for the Otto, the UGV which won 3rd Place Internationally@AutoNav Challenge, Intelligent Ground Vehicle Competition(IGVC) 2023.",
        "Designed and implemented the end-to-end mission control software that performs autonomous navigation, payload drop and coverage planning for AUVSI SUAS 2022.",
        "Developed and integrated visual odometry-based reactive control with an attention-based lane detection model on the teams self-driving car.",
       

// • Developed a lane following/switching module using an attention-based lane detection model on Project
// MANAS’ autonomous car. Implemented the Stanley Lateral Controller us-
// ing stereo camera-based visual odometry. Worked on navigation and localization for the software stack
// • Performed extensive self-driving car simulation building with CARLA-ROS and Gazebo and tested
// steering angles on a Logitech Steering Wheel interface.
// • Designed and implemented the end-to-end deployable nav-server software that performs autonomous navigation, payload drop and mission completion for AUVSI SUAS 2022. 
      ]
    },
    "The Salem Aeropark": {
      jobTitle: "Robotic Software Developer Intern -",
      company: "Autonomous & Undersea Systems Division, AEPL",
      duration: "FEB 2023 - MAY 2023",
      desc: [
        "Developed a ROS action server model resposible for mission control of a underwater submarine.",
        "Developed a mapping and navigation software capable of sonar based localisation and real time obstacle avoidance.",
      ]
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>

          <span className="joblist-job-company"><a href={experienceItems[key]["link"]}>{experienceItems[key]["company"]}</a>
          </span>

          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
          <div className="about-image">
    <img src={"/logos/cern.png"}  />
    <img src={"/logos/princeton.png"}  />
    <img src={"/logos/gsoc.png"}  />
    <img src={"/logos/iisc.png"}  />
    <img src={"/logos/peer.png"}  />
    <img src={"/logos/compres.png"}  />
  </div>
    </div>

  );
};

export default JobList;
