import React from "react";
import "../styles/Video.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import ExternalLinks from "./ExternalLinks";

class Video extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const spotlightProjects = {
      "Vid": {
        title: "my journey in ai & robotics",
        desc:
          "a quick video of my work highlights",
        link: "",
        open: "",
        video: "/assets/website_vid.mp4"
      }
    };

    return (
      <div id="video">
        <div className="section-header ">
          <span className="section-title">/ my journey in ai & robotics</span>
        </div>
        
        {/* <Carousel>
          {Object.keys(spotlightProjects).map((key, i) => (
            <Carousel.Item> */}

              

{/* <video width="750" height="500" controls ></video> */}
             <div> <video
                borderRadius="24px"
                width="400px"
                height="500"
                autoPlay
                loop
                muted
                className="d-block w-100"
                src="/assets/website_vid.mp4"
                type = 'video/mp4'
               />
               </div>


            {/* </Carousel.Item>
          ))}
        </Carousel>  */}
        
      </div>
    );
  }
}
export default Video;

{/* <video className='videoTag' id = "video_style" autoPlay loop muted opacity = "0 ">
                    <source src={sample} type='video/mp4' />
                    </video> */}