import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const one = (
      <p>
        I am currently an intern at <b>CERN</b> working on the ROOT software project. In August 2024, I recieved my bachelors degree in Computer Science from
        <a href="https://manipal.edu/mit.html">
          {" "}Manipal Institute of Technology.{" "}
        </a>
        My primary research interests lie in high-performance scientific computing frameworks and their applications in computational physics and autonomous robotics.
        {/* a PhD in Computer Science while focusing on  */}
         
      </p>
    );
    const two = (
      <p>

Heres a link to my 
<a href="https://github.com/maximusron/qgss_2021_labs"> GitHub</a> on Quantum Machine Learning at IBM's Qiskit Summer School 2021. Outside of work, I'm interested in photography, art and automobiles. 
        I'm also an amateur chef who enjoys travelling and watching football.

      </p>
    );
    const three = (
      <p>
        <b>Want to chat?</b> Shoot me a message at{" "}
        <a href="mailto:aaronjomyjoseph@gmail.com">
          aaronjomyjoseph@gmail.com
        </a>{" "}
        and let's talk.
      </p>
    );
    const desc_items = [one, two];

    const tech_stack = [
      "C", "C++", "Python", "Java", "LLVM", "Numba",
       "Tensorflow",
       "PyTorch",
       "ROS2",
       "Gazebo" ,
       "Linux ",
       "OpenCV" ,
       "PointCloudLibrary (PCL)"

      
    ];

    const tech_items = tech_stack.map(stack => <li>{stack}</li>);

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">/ about me</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {[one]}
              {"Here are some technologies I have been working with:"}
              <ul className="tech-stack">
                {tech_stack.map(function (tech_item, i) {
                  return (
                    <FadeInSection delay={`${i + 1}00ms`}>
                      <li>{tech_item}</li>
                    </FadeInSection>
                  );
                })}
              </ul>
              {[two]}
            </div>
            <div className="about-image">
            <img src={"/assets/cms.jpg"}  />
            <img src={"/assets/copernicus.jpg"} width = {768} height={1024} />
            </div>
            <div className="about-image">
            <video
                // padding-top = 4%
                autoPlay
                loop
                muted
                className="d-block w-100"
                src="/assets/cop_demo.mp4"
                type = 'video/mp4'
              />
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;
