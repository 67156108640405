import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import ExternalLinks from "./ExternalLinks";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const spotlightProjects = {
      "StyleGAN2 Distillation": {
        title: "feedforward stylegan2 distillation",
        desc:
          "morph between people, and manipulate age, gender and expression",
        techStack: "PyTorch / Tensorflow ",
        link: "https://github.com/maximusron/FeedForward-StyleGAN2-Distillation",
        open: "",
        image: "/assets/stylegan_gif.gif"
      },
      "ariacs": {
        title: "ariac-tb3-gazebo",
        desc:
          "a gazebo testbench for DepthCam and LiDAR based segmentation and object clustering ",
        techStack: "ROS / SLAM / C++ / Gazebo / PCL",
        link: "https://github.com/maximusron/ariac_gazebo_lidar",
        open: "",
        image: "/assets/ariac.png"
      },
      "nst": {
        title: "resnet-50 neural style transfer",
        desc:
          "capture and incorporate art styles using my resnet-50 based AI model",
        techStack: "TensorFlow",
        link: "https://github.com/maximusron/Probabilistic-Image-Manipulation",
        open: "",
        image: "/assets/nst.png"
      },
      "ros2": {
        title: "ros2-neural-a*",
        desc:
          "data-driven approach to machine learning aided search-based path planning.",
        techStack: "ROS2 / Gazebo / PyTorch / SLAM",
        link: "https://github.com/maximusron/ros2-neural-astar",
        open: "",
        image: "/assets/neuralastar.gif"
      }
    };
    const projects = {
      "Variational Graph AutoEncoder Particle Tracker": {
        desc:
          "Ensemble of Graph Neural Network models designed for particle trajectory link prediction. Tuned for predicting tracks of subatomic particle datasets collected from collider experiments(TrackML) ",
        techStack: "PyTorch Geometric",
        link: "https://github.com/maximusron/GNN_TrackParticle",
        open: ""
      },
      "Feedforward image distillation on Nvidia’s StyleGAN2": {
        desc:
          "Feedforward image distillation on Nvidia’s StyleGAN2, on facial data that perform Morphing and Expression transfer. Developed an image-image latent space pipeline model based on pix2pixHD architecture",
        techStack: "Python: PyTorch, TensorFlow Probability, GANs",
        link: "https://github.com/maximusron/FeedForward-StyleGAN2-Distillation",
        open: ""
      },
      "ResNet50 derived Neural Style Transfer": {
        desc:
          "A mini research project on aiming to replicate the widely accepted NST performance by commonly used models like VGG or TensorFlow's NST model.",
        techStack: "Python, TensorFlow",
        link: "https://github.com/maximusron/Probabilistic-Image-Manipulation",
        open: "",
        image: "/assets/nst.jpg"
      },
      "ARIAC TB3 simulation testbench for Point Cloud segmentation": {
        desc:
          "ARIAC simulation setup with TB3 as a testbench for PCL modules using Euclidean and DoN segmentation with LiDAR and Intel Realsense d435 point cloud inputs",
        techStack: "C++, ROS, SLAM, Gazebo, PCL",
        link:
          "https://github.com/maximusron/ariac_gazebo_lidar"
      },
      "ROS2 Neural A* Path Planner module": {
        desc:
          "ROS2 plugin for a data-driven approach to machine learning aided search-based path planning. Designing a modified Neural A* planner trained on cost maps generated from indoor robot simulations using ROS2’s navigation2 stack.",
        techStack: "ROS2, Gazebo, PyTorch, PCL, SLAM",
        link: "https://github.com/maximusron/ros2-neural-astar",
        open: ""
      },
     
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ projects</span>
        </div>
        <Carousel>
          {Object.keys(spotlightProjects).map((key, i) => (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={spotlightProjects[key]["image"]}
                alt={key}
              />
              <div className="caption-bg">
                <Carousel.Caption>
                  <h3>{spotlightProjects[key]["title"]}</h3>
                  <p>
                    {spotlightProjects[key]["desc"]}
                    <p className="techStack">
                      {spotlightProjects[key]["techStack"]}
                    </p>
                  </p>
                  <ExternalLinks
                    githubLink={spotlightProjects[key]["link"]}
                    openLink={spotlightProjects[key]["open"]}
                  ></ExternalLinks>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <ExternalLinks
                      githubLink={projects[key]["link"]}
                      openLink={projects[key]["open"]}
                    ></ExternalLinks>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
